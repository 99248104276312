import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const Masonry = styled.div`
  column-count: 3;
  column-gap: 10px;
  line-height: 0;
  width: 100%;

  @media (max-width: 1200px) {
    column-count: 2;
  }
  @media (max-width: 400px) {
    column-count: 1;
  }
`

const MasonryBrick = styled(Img)`
  background-color: #eee;
  border-radius: 5px;
  display: inline-block;
  margin: 0 0 10px;
  width: 100%;
`

export default props => {
  return (
    <Masonry>
      {props.photos.map(photo => {
        return (
          <MasonryBrick fluid={photo.asset.fluid} key={photo._key} alt="" />
        )
      })}
    </Masonry>
  )
}
