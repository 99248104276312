import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Masonry from "../components/masonry"

const PhotoPageTemplate = ({ title, photos }) => {
  return (
    <Layout>
      <SEO title={title} />
      <h1 className="isVisuallyHidden">{title}</h1>
      <Masonry photos={photos} />
    </Layout>
  )
}

export default ({ data }) => {
  const page = data.sanityGalleries

  return <PhotoPageTemplate title={page.title} photos={page.pictures} />
}

export const photoPageQuery = graphql`
  query PhotoPage($id: String!) {
    sanityGalleries(id: { eq: $id }) {
      id
      title
      pictures {
        _key
        asset {
          fluid(maxWidth: 750) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  }
`
